import { Container, Grid, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/styles";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/SEO";

const useStyles = makeStyles(theme => ({
  notFoundPageHeroContainer: {
    textAlign: "center",
    margin: theme.spacing(5, 0, 25)
  },
  notFoundPageTitle: {
    marginBottom: theme.spacing(2)
  },
  notFoundPageClearIcon: {
    fontSize: "10rem",
    marginLeft: "-1.5rem",
    color: red[100]
  }
}));

const NotFoundPage = ({ uri }) => {
  const classes = useStyles();
  const title = "404 Not found";
  const description = `The page at ${uri} does not exist here.`;

  return (
    <Layout>
      <SEO title={title} description={description} />
      <Section className={classes.notFoundPageHeroContainer}>
        <Container>
          <Grid container justify="center">
            <Grid item xs={12} lg={10}>
              <ClearIcon className={classes.notFoundPageClearIcon} />
              <Typography className={classes.notFoundPageTitle} variant="h1">
                {title}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                giant
                component={GatsbyLink}
                to="/"
              >
                Go Home
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
